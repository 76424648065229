import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import {
  Section,
  PageHeader,
  SimpleCallToActionBanner,
  FaqBlock,
  PricingCard
} from '../components'
import * as styles from '../styles/pages/pricing'
import variables from '../styles/variables'
import sandbox from '../../assets/hobby-color.svg'
import dev from '../../assets/dev-color.svg'
import prod from '../../assets/prod-color.svg'
import enterprise from '../../assets/rocket-color.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function PricingPage({ data }) {
  const { allYaml } = data
  const { edges } = allYaml
  const { node } = _.first(edges)
  const headerIcons = [
    <styles.PlanIcon src={sandbox} alt="plan" />,
    <styles.PlanIcon src={dev} alt="plan" />,
    <styles.PlanIcon src={prod} alt="plan" />,
    <styles.PlanIcon src={enterprise} alt="plan" />
  ]
  const firstChild = (
    <PageHeader
      header={node.header}
      info={node.headerInfo}
    />
  )
  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={node.metaDescription}
      sharingTitle={node.title}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <Section>
        <styles.Container>
          <styles.IntroSectionContainer>
            <styles.SectionRow>
              {_.map(node.pricingPlans, (plan, i) => {
                const pricingPlan = { ...plan, icon: headerIcons[i] }
                return (
                  <styles.SectionCol lg={3} sm={6} xs={12} key={i}>
                    <PricingCard pricingPlan={pricingPlan} />
                  </styles.SectionCol>
                )
              })}
            </styles.SectionRow>
          </styles.IntroSectionContainer>

          <FaqBlock id="faq" data={node} />

          <SimpleCallToActionBanner />
        </styles.Container>
      </Section>
    </PageLayout>
  )
}

PricingPage.propTypes = propTypes

export const contentQuery = graphql`
query GetPricingContent {
  allYaml(filter: {file: {eq: "pricing"}}) {
    edges {
      node {
        title
        header
        headerInfo
        metaDescription
        pricingSection1Header
        pricingSection1Text

        pricingPlans {
          id
          header
          text
          features {
            text
            moreInfo
            explain
          }
          price {
            text
            moreInfo
          }
          button {
            url
            text
          }
        }

        faqHeader
        faqText
        faq {
          id
          header
          text
        }
      }
    }
  }
}
`
