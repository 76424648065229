import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import variables from '../variables'
import { TextWrapper } from '../base'

export const Container = styled.div`
  width: 100%;
`
export const SectionContainer = styled(TextWrapper)`
  color: ${variables.colors.oracle.dark};
  margin-top: 20px !important;
  @media ${variables.media.sm} {
    margin-top: 60px !important;
  }
`
export const IntroSectionContainer = styled(SectionContainer)`
  margin-bottom: 20px !important;
  @media ${variables.media.sm} {
    margin-top: 0 !important;
  }
`
export const SectionRow = styled(Row)`
  width: 100%;
`
export const SectionCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const MailTo = styled.a`
  color: ${variables.colors.jade.medium};
`
export const PlanIcon = styled.img`
  width: 80px;
  height: 80px;
`
